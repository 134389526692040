// $(".collapseCardCta").on("click", function () {
//   $(this).text(function (i, old) {
//     return old == "Read More" ? "Read Less" : "Read More";
//   });
// });

(function ($) {
  $(function () {
    // Solutions filters
    // $(".-solutions-full .-wrapper .-item").on({
    //   click: function () {
    //     var cardToShow = $(this).data("item");
    //     $(".-select #default-select").val(cardToShow).change();
    //     if (cardToShow !== "all") {
    //       $(".-item").removeClass("active");
    //       $(".-list .card").removeClass("show");
    //       $(".-list .card").addClass("hide");
    //       $(this).addClass("active");
    //       $("#" + cardToShow).removeClass("hide");
    //       $("#" + cardToShow).addClass("show");
    //     } else {
    //       $(".-list .card").removeClass("hide");
    //       $(".-list .card").addClass("show");
    //     }
    //   },
    // });
    // $(".-solutions-full .-select #default-select").on({
    //   change: function () {
    //     var cardToShow = $(this).val();
    //     $(".-item").removeClass("active");
    //     var item = $(".-item[data-item='" + cardToShow + "']");
    //     item.addClass("active");

    //     if (cardToShow !== "all") {
    //       $(".-list .card").removeClass("show");
    //       $(".-list .card").addClass("hide");
    //       $("#" + cardToShow).removeClass("hide");
    //       $("#" + cardToShow).addClass("show");
    //     } else {
    //       $(".-list .card").removeClass("hide");
    //       $(".-list .card").addClass("show");
    //     }
    //   },
    // });

    /**
     * New filter function
     */

    function filterEvents() {
      $(".warning").addClass("hide");
      $(".warning").removeClass("show");

      const selectedArea = $(".-areas .-item.active").attr("data-area");
      const selectedIndustry = $(".-formats .-item.active").attr("data-format");

      const matchingAreaCards =
        selectedArea == "all"
          ? $(".-card[data-area!='']")
          : $(".-card[data-area='" + selectedArea + "']");

      $(".result .-card").addClass("hide");
      $(".result .-card").removeClass("show");

      matchingAreaCards.each(function () {
        const thisCardIndustry = $(this).attr("data-format");
        const thisCardExclude = $(this).attr("data-exclude");

        if (selectedIndustry == "all" && thisCardExclude == "false") {
          $(this).removeClass("hide");
          $(this).addClass("show");

          return true;
        }

        if (thisCardIndustry == selectedIndustry) {
          console.log(thisCardIndustry, selectedIndustry, "match!", this);

          $(this).removeClass("hide");
          $(this).addClass("show");
        }
      });

      console.log("cards showing:", $(".-card.show").length);

      //if no matched cards
      if ($(".result .-card.show").length == 0) {
        $(".warning").removeClass("hide");
        $(".warning").addClass("show");
      }
    }

    /**
     * Area Filters
     */

    $(".tpl_whatson .-areas .-item").on({
      click: function () {
        $(".-areas .-item").removeClass("active");
        $(this).addClass("active");

        const selectedArea = $(this).attr("data-area");

        $(".-select #area-select").val(selectedArea).change();
      },
    });

    $(".tpl_whatson .-select #area-select").on({
      change: function () {
        var val_area = $(this).val();

        $(".-areas .-item").removeClass("active");
        $(".-item[data-area='" + val_area + "']").addClass("active");

        filterEvents();
      },
    });

    /**
     * Formats Filters
     */

    $(".tpl_whatson .-formats .-item").on({
      click: function () {
        //highlight
        $(".-formats .-item").removeClass("active");
        $(this).addClass("active");

        const selectedFormat = $(this).attr("data-format");

        $(".-select #format-select").val(selectedFormat).change();

        return false;
      },
    });

    $(".tpl_whatson .-select #format-select").on({
      change: function () {
        var val_format = $(this).val();

        $(".-formats .-item").removeClass("active");
        $(".-item[data-format='" + val_format + "']").addClass("active");

        filterEvents();
      },
    });

    /**
     * toggle all speakers
     */
    $("#showall_speakers").on({
      click: function () {
        $(".el-speakers__grid-card .-card")
          .removeClass("hide")
          .addClass("show");
        $(this).addClass("hide");
      },
    });

    /**
     * toggle tier sponsors
     */
    $(".showall").on({
      click: function () {
        var el = $(this).attr("data-toggle");
        var elem = el + " .-card";
        console.log(elem);
        $(elem).removeClass("hide").addClass("show");
        $(this).addClass("hide");
      },
    });

    /**
     * toggle tooltips
     */
    $(".-tooltip").on({
      click: function () {
        var obj = $(this).data("obj");
        $(obj).toggleClass("hide");
      },
    });

    //todo: Bootstrap.() OR .popover not define issue?
    // $(".popper").Popover({
    //   placement: "bottom",
    //   container: "body",
    //   html: true,
    //   content: function () {
    //     return $(this).next(".popper-content").html();
    //   },
    // });
  });
})(jQuery);

document.addEventListener("DOMContentLoaded", function () {
  // Loop through each solutions section (identified by the unique ID)
  document
    .querySelectorAll('[id^="solutions-"]')
    .forEach((solutionsSection) => {
      console.log(solutionsSection);
      // Filter Items Click Handler
      solutionsSection.querySelectorAll(".-filters .-item").forEach((item) => {
        item.addEventListener("click", function () {
          let filter = this.dataset.item;
          console.log(filter);

          // Update active state of filter items
          this.parentNode.querySelectorAll(".-item").forEach((sibling) => {
            sibling.classList.remove("active");
          });
          this.classList.add("active");

          // Filter the cards
          solutionsSection.querySelectorAll(".card").forEach((card) => {
            // Correct scoping
            if (filter === "all" || card.dataset.solution === filter) {
              card.style.display = "block";
            } else {
              card.style.display = "none";
            }
          });

          // Update select element
          let defaultSelect = solutionsSection.querySelector(".select select"); // select the select inside the .select
          defaultSelect.value = filter;
        });
      });

      //Dropdown Listener
      const defaultSelect = solutionsSection.querySelector(".select select");
      defaultSelect.addEventListener("change", function () {
        let filter = this.value;
        solutionsSection.querySelectorAll(".card").forEach((card) => {
          if (filter === "all" || card.dataset.solution === filter) {
            card.style.display = "block";
          } else {
            card.style.display = "none";
          }
        });

        solutionsSection
          .querySelectorAll(".-filters .-item")
          .forEach((item) => {
            item.classList.remove("active");
            if (item.dataset.item == filter) {
              item.classList.add("active");
            }
          });
      });
    });
});
